html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  margin: 0;
  background-color: #eeeff1;
  font-family: "Poppins", "-apple-system", "BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Helvetica Neue", "Arial", "sans-serif", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

.addNotesSelectButton:disabled {
  border: 1px solid #1373E680;
  background-color: #1373E680;
  color: #fff;
}


/* Shared colors across brands */
.error,
.failedValidation {
  color: #f44336;
}

.success,
.successfulValidation {
  color: green;
}

.negativeAmount {
  color: #a20000 !important;
}

/* Sizing and layout updates for Material UI to work properly with our layout */
#root {
  height: 100%;
  scroll-behavior: smooth;
}

#appHeader .loading {
  min-height: 50px;
}

.Mui-disabled,
.Mui-disabled .MuiInputBase-root {
  opacity: 0.4;
}

.MuiAutocomplete-root .MuiInputBase-root {
  overflow-y: auto;
  max-height: 40px;
}

.MuiAutocomplete-option {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox {
  overflow-x: hidden;
}

.MuiAutocomplete-root .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0) !important;
}

.MuiTabs-indicator {
  bottom: auto !important;
}

.MuiAutocomplete-root {
  min-width: 320px;
}

@media (min-width: 720px) {
  .MuiAutocomplete-root {
    display: inline-block;
    width: 400px;
  }
}

.submitButton {
  margin: 5px 10px;
  vertical-align: bottom;
}

.MuiAutocomplete-root .MuiInputBase-root::before,
.MuiAutocomplete-root .MuiInputBase-root::after {
  display: none;
}

/* svgs */
.st0 {
  opacity: 1 !important;
}

#userIcon .st0 {
  fill: #1373e6 !important;
  stroke: #1373e6 !important;
}

/* IE11 Hacks */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #appHeader {
    position: static;
    height: 60px;
  }

  #logoContainer {
    height: 40px;
    padding: 10px;
  }
}

.MuiTableHead-root .MuiTableRow-root {
  background: #f1f1f1;
  border-right: 1px solid #000;
}

.MuiTableBody-root .MuiTableRow-root:nth-child(2n) {
  background: #f5f5f5;
  border-right: 1px solid #000;
}

/* Non-material-ui elements */
.navigationLink {
  border-radius: 0;
  padding: 8px 24px;
  height: 60px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  font-weight: normal;
  border-bottom: 4px solid transparent;
}

.navigationLink.active {
  color: #1373e6;
  border-bottom-color: #1373e6;
}
/* download to excel */
.download-excel-button {
  border: none;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  color: #1976d2;
  padding: 6px;
  border-radius: 3px;
  cursor: pointer;
}

.download-excel-button img {
  width: 25px;
  margin-left: 5px;
}

.wait-excel-button {
  border: none;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  color: #1976d2;
  padding: 6px;
  border-radius: 3px;
  cursor: progress;
}

.wait-excel-button img {
  width: 25px;
  margin-left: 5px;
}
.excel-export-container {
  margin: 0px 0px;
}
.excel-export-container :hover {
  background: #f6fafd;
}

.excel-table {
  width: 100%;
}

.excel-table-wrapper {
  margin: 15px 0px;
  max-height: 200px;
  overflow-y: scroll;
}

.excel-table tr:first-of-type {
  display: none;
}

.excel-table tr {
  background-color: #c6e6f5;
}

.excel-table tr:nth-of-type(2) {
  background-color: #3d5662 !important;
  color: #fff;
}

.excel-table tr:nth-of-type(even) {
  background-color: #e6fbff;
}

.excel-table td {
  padding: 5px 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.PrivatePickersSlideTransition-root {
  min-height: 210px !important;
}
